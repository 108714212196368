<template>
  <section id="voucher">
    <div v-if="!loading">
      <v-row>
        <v-col cols="12" xl="8" lg="10">
          <div class="text-h5 ml-2 mb-5">Manage Vouchers</div>
          <v-row no-gutters class="mb-0 pb-0">
            <v-col cols="12" lg="3">
              <search :store="store" :action="action" />
            </v-col>
            <v-col cols="12" lg="3">
              <date :store="store" :action="action" />
            </v-col>
            <v-col cols="12" lg="3">
              <count :store="store" :action="action" />
            </v-col>
            <v-col cols="12" lg="3">
              <v-btn @click="fetch" small class="mt-1 mx-2" color="warning" fab>
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn
                @click="$emit('vouche')"
                small
                class="mt-1"
                color="primary"
                fab
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="8" lg="9">
          <VoucherTable :data="vouchers.data" />
          <!-- <v-row>
            <v-col
              cols="12"
              md="4"
              v-for="item in vouchers.data"
              :key="item.id"
            >
              <Card :voucher="item" :dark="user.dark" />
            </v-col>
          </v-row> -->

          <paginate
            v-if="vouchers.total > 0"
            :store="store"
            collection="vouchers"
          />
        </v-col>
      </v-row>
    </div>

    <div class="body-1" v-else>Loading...</div>

    <VoucherStudent :dialog="dialog" @close="dialog = false" />
  </section>
</template>

<script>
import { mapState } from "vuex";
import VoucherStudent from "../components/voucher/VoucherModal.vue";
import VoucherTable from "../components/voucher/VoucherTable.vue";
// import Card from "../components/voucher/Card.vue";

export default {
  components: {
    VoucherStudent,
    VoucherTable,
    // Card,
  },
  data: () => ({
    loading: true,
    dialog: false,
    store: "admin",
    action: "vouchersGetAction",
    snack: false,
    snackText: "",
    vouche: false,
  }),
  computed: {
    ...mapState("admin", {
      students: (state) => state.students,
      vouchers: (state) => state.vouchers,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.commit("admin/studentMutation", []);
      this.$store.commit("admin/resetPageMutation");
      this.$store.dispatch("admin/allCoursesAction");
      this.$store
        .dispatch("admin/vouchersGetAction", {
          page: 0,
          query: "",
          date: "",
          count: 10,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {
    this.$store.commit("admin/studentMutation", []);
    this.$store.commit("admin/vouchersMutation", []);
  },
};
</script>
