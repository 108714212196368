<template>
  <v-simple-table fixed-header class="pt-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">Code</th>
          <!-- <th class="text-left caption">Users</th> -->
          <th class="text-left caption">Courses</th>
          <th class="text-left caption">Created</th>
          <th class="text-left caption">
            Row count
            {{
              $store.getters["admin/countGetters"] == 0
                ? data.length
                : `${$store.getters["admin/vouchersGetters"].to} of ${$store.getters["admin/vouchersGetters"].total}`
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.name">
          <td class="">
            <div class="d-flex align-center">
              <v-icon size="14">mdi-ticket-outline</v-icon>
              <div class="body-2 ml-2">
                <i>{{ item.code }}</i>
              </div>
            </div>
          </td>
          <!-- <td class="">
            <div class="d-flex align-center">
              <v-icon size="14">mdi-account-multiple-outline</v-icon>
              <div class="body-2 ml-2">{{ item.count }}</div>
            </div>
          </td> -->
          <td class="">
            <div class="d-flex align-center">
              <v-icon size="14">mdi-book-multiple-outline</v-icon>
              <div class="body-2 ml-2">{{ item.courses_count }}</div>
            </div>
          </td>
          <td class="caption">{{ $helpers.dateDiff(item.created_at) }}</td>
          <td class="body-2">
            <v-btn text small color="primary" class="button" @click="details(item.id)">
              View Details
            </v-btn>
            <v-btn text small class="button" @click="delVouche(item.id)"> Delete </v-btn>
          </td>

          <!-- <td>
            <v-btn icon>
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn icon class="mx-2" @click="$emit('delete', item.id)">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </td> -->
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
var CryptoJS = require("crypto-js");

import { mapActions } from "vuex";
export default {
  props: ["data"],
  methods: {
    ...mapActions("admin", ["deleteVoucher"]),

    details(id) {
      let key = CryptoJS.AES.encrypt(id.toString(), "secret").toString();
      console.log(key);
      this.$router.push({
        name: "Admin Voucher",
        params: {
          id: key,
        },
      });
    },

    delVouche(id) {
      if(confirm('Are you sure? You want to delete this voucher?')) {
        console.log(id);
        this.deleteVoucher(id)
      }
      
    },
  },
};
</script>
