<template>
  <v-dialog persistent max-width="550" v-model="dialog">
    <v-card>
      <v-card-title class="d-flex 
      justify-space-between 
      align-center 
      success 
      white--text">
        <div class="">Student List</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="students"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['students', 'dialog'],
  data: () => ({
    headers: [
      {
        text: 'Member Type',
        align: 'start',
        sortable: false,
        value: 'level',
      },
      { text: 'Name', value: 'name' },
    ],
  })
}
</script>